<template>
  <div>
    <div class="section section-pd bg-pr-trans">
      <div class="page-header">
        <div
          class="
            container
            shape-container
            d-flex
            align-items-center
            text-center-sm
          "
        >
          <div class="col px-0">
            <div class="row align-items-center justify-content-center">
              <div class="col-lg-5">
                <h1 class="text-dark display-1 f-bold text-head mb-3">
                  Become a Vendor
                </h1>
                <p class="f-16 text-dark mb-0">
                  Register with imperium and become a venndor in real time.
                </p>
              </div>
              <div class="col-md-7">
                <div class="text-center">
                  <img
                    class="headerimg"
                    src="/assets/images/svgs/becomevendor.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="my-5">
      <div class="container">
        <div class="vendorFormDiv">
          <form @submit.prevent="submitForm()">
            <div class="row">
              <div class="form-group contactForm col-md-6">
                <label>Email address</label>
                <input required class="form-control" type="email" />
              </div>
              <div class="form-group contactForm col-md-6">
                <label>Business name</label>
                <input required class="form-control" type="text" />
              </div>
              <div class="form-group contactForm col-md-6">
                <label>Vendor name</label>
                <input required class="form-control" type="text" />
              </div>
              <div class="form-group contactForm col-md-6">
                <label>Phone number</label>
                <input required class="form-control" type="text" />
              </div>
              <div class="form-group contactForm col-md-6">
                <label>Alternate Phone number</label>
                <input required class="form-control" type="text" />
              </div>
              <div class="form-group contactForm col-md-6">
                <label>Business address</label>
                <input required class="form-control" type="text" />
              </div>
              <div class="form-group contactForm col-md-6">
                <div>
                  <label>CAC Number</label>
                  <input required class="form-control" type="text" />
                  <div>
                    <label style="cursor: pointer" for="logo">
                      <div
                        class="uploadView"
                        :style="{
                          background: logoDisplay
                            ? `url(${logoDisplay})`
                            : `#e0ecce`,
                        }"
                      >
                        <Loading class="loadingCir" v-if="uploading" />
                        <img
                          v-else
                          src="/assets/images/svgs/upload.svg"
                          alt=""
                        />
                      </div>
                      <input
                        style="display: none"
                        name="logo"
                        id="logo"
                        :disabled="uploading"
                        accept="image/png, image/jpeg, image/jpg"
                        @change="handleFileChange($event)"
                        type="file"
                      />
                      <p>Upload Vendor Logo</p>
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-group contactForm col-md-6">
                <label>Brief description of services/ prodcts provided</label>
                <textarea
                  class="form-control"
                  name=""
                  id=""
                  rows="8"
                ></textarea>
              </div>
            </div>
            <div class="form-group mt-4">
              <button
                :disabled="loading"
                class="
                  btn btn-imp-secondary
                  bg-primary
                  btn-block
                  text-white
                  mt-2
                  py-3
                  px-5
                "
              >
                <span class="nav-link-inner--text">Submit</span>
                <BtnLoading v-if="loading" class="btn-loading" />
              </button>
              <p class="mt-4 text-center">
                Already have an account?
                <a
                  href="https://vendor.imperiumng.com/#/login"
                  target="_blank"
                  class="text-primary"
                  >Login</a
                >
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BtnLoading from "@/components/App/BtnLoading.vue";
import Loading from "@/components/App/Loading.vue";

export default {
  components: { BtnLoading, Loading },
  data() {
    return {
      loading: false,
      uploading: false,
      logoFile: "",
      logoDisplay: "",
      // ....
      photo: "",
    };
  },
  methods: {
    handleFileChange: function (event) {
      this.logoFile = event.target.files[0];
      this.uploading = true;
      this.logoDisplay = window.URL.createObjectURL(event.target.files[0]);

      var data = new FormData();
      data.append("upload_preset", "Imperium-ng");
      data.append("file", this.logoFile);

      let payload = {
        data,
        path: "https://api.cloudinary.com/v1_1/imperiumng/image/upload",
      };
      this.$store
        .dispatch("postRequest", payload)
        .then((resp) => {
          this.uploading = false;
          console.log(resp.data.secure_url);
          this.photo = this.resp.data.secure_url;
          this.$toast.success(
            "Logo Upload",
            "Logo uploaded successfully",
            this.$toastPosition
          );
        })
        .catch((err) => {
          this.uploading = false;
          if (err.response) {
            this.$toast.info(
              "Logo Upload",
              err.response.data.message,
              this.$toastPosition
            );
          } else {
            this.$toast.info(
              "Logo Upload",
              "Unable to upload logo, check image and try again",
              this.$toastPosition
            );
          }
          this.loading = false;
        });
    },
    submitForm() {
      this.loading = true;
      let data = {
        email: this.email,
        phone_number: this.phone_number,
        contact_reference: this.contact_reference,
        contact_date: this.contact_date,
        address: this.address,
        first_name: this.first_name,
        last_name: this.last_name,
      };
      let payload = {
        data,
        path: "/vendor",
      };
      this.$store
        .dispatch("postRequest", payload)
        .then((resp) => {
          this.loading = false;
          this.email = "";
          this.phone_number = "";
          this.contact_reference = "";
          this.contact_date = "";
          this.address = "";
          this.first_name = "";
          this.last_name = "";

          this.$toast.success(
            "Request Submit",
            "Your Request has been sent successfully",
            this.$toastPosition
          );
        })
        .catch((err) => {
          if (err.response) {
            this.$toast.info(
              "Request Submit",
              err.response.data.message,
              this.$toastPosition
            );
          } else {
            this.$toast.info(
              "Request Submit",
              "Unable to submit request, check the details and try again",
              this.$toastPosition
            );
          }
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss">
#terms-and-conditions {
  color: black;
  h3 {
    color: #219653;
    opacity: 0.8;
    font-weight: bold;
  }
  h4 {
    color: black;
    font-size: 18px;
    font-weight: bold;
  }
}
#terms-and-conditions .details table {
  width: 100%;
  font-size: 1.1rem;
  border-collapse: collapse;
  margin-bottom: 2rem;
  th {
    font-weight: 600;
  }
}
#terms-and-conditions .details table td,
#terms-and-conditions .details table th {
  border: 1px solid #ddd;
  text-align: left;
  padding: 8px;
  padding-left: 2rem;
}
.vendorFormDiv {
  border: 1px solid #255e13;
  box-sizing: border-box;
  border-radius: 30px;
  padding: 30px;
}
.contactForm {
  label {
    color: black;
  }
  input,
  textarea {
    border: 1px solid #219653;
    border-radius: 15px;
  }
}
.loadingCir {
  width: 43px;
  height: 43px;
  background: #ffffffd8 !important;
  padding: 9px;
  border-radius: 41px;
}
.uploadView {
  height: 132px;
  width: 132px;
  background: #e0ecce;
  border: 2px dashed rgba(33, 150, 83, 0.5);
  margin: 19px 0px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover !important;
  background-position: center !important;
  img {
    width: 43px;
    height: 43px;
    background: #0000004d;
    padding: 9px;
    border-radius: 41px;
  }
}
</style>