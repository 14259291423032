var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"my-5"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"vendorFormDiv"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm()}}},[_c('div',{staticClass:"row"},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('div',{staticClass:"form-group contactForm col-md-6"},[_c('div',[_c('label',[_vm._v("CAC Number")]),_c('input',{staticClass:"form-control",attrs:{"required":"","type":"text"}}),_c('div',[_c('label',{staticStyle:{"cursor":"pointer"},attrs:{"for":"logo"}},[_c('div',{staticClass:"uploadView",style:({
                        background: _vm.logoDisplay
                          ? `url(${_vm.logoDisplay})`
                          : `#e0ecce`,
                      })},[(_vm.uploading)?_c('Loading',{staticClass:"loadingCir"}):_c('img',{attrs:{"src":"/assets/images/svgs/upload.svg","alt":""}})],1),_c('input',{staticStyle:{"display":"none"},attrs:{"name":"logo","id":"logo","disabled":_vm.uploading,"accept":"image/png, image/jpeg, image/jpg","type":"file"},on:{"change":function($event){return _vm.handleFileChange($event)}}}),_c('p',[_vm._v("Upload Vendor Logo")])])])])]),_vm._m(7)]),_c('div',{staticClass:"form-group mt-4"},[_c('button',{staticClass:"btn btn-imp-secondary bg-primary btn-block text-white mt-2 py-3 px-5",attrs:{"disabled":_vm.loading}},[_c('span',{staticClass:"nav-link-inner--text"},[_vm._v("Submit")]),(_vm.loading)?_c('BtnLoading',{staticClass:"btn-loading"}):_vm._e()],1),_vm._m(8)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section section-pd bg-pr-trans"},[_c('div',{staticClass:"page-header"},[_c('div',{staticClass:"container shape-container d-flex align-items-center text-center-sm"},[_c('div',{staticClass:"col px-0"},[_c('div',{staticClass:"row align-items-center justify-content-center"},[_c('div',{staticClass:"col-lg-5"},[_c('h1',{staticClass:"text-dark display-1 f-bold text-head mb-3"},[_vm._v(" Become a Vendor ")]),_c('p',{staticClass:"f-16 text-dark mb-0"},[_vm._v(" Register with imperium and become a venndor in real time. ")])]),_c('div',{staticClass:"col-md-7"},[_c('div',{staticClass:"text-center"},[_c('img',{staticClass:"headerimg",attrs:{"src":"/assets/images/svgs/becomevendor.svg","alt":""}})])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group contactForm col-md-6"},[_c('label',[_vm._v("Email address")]),_c('input',{staticClass:"form-control",attrs:{"required":"","type":"email"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group contactForm col-md-6"},[_c('label',[_vm._v("Business name")]),_c('input',{staticClass:"form-control",attrs:{"required":"","type":"text"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group contactForm col-md-6"},[_c('label',[_vm._v("Vendor name")]),_c('input',{staticClass:"form-control",attrs:{"required":"","type":"text"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group contactForm col-md-6"},[_c('label',[_vm._v("Phone number")]),_c('input',{staticClass:"form-control",attrs:{"required":"","type":"text"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group contactForm col-md-6"},[_c('label',[_vm._v("Alternate Phone number")]),_c('input',{staticClass:"form-control",attrs:{"required":"","type":"text"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group contactForm col-md-6"},[_c('label',[_vm._v("Business address")]),_c('input',{staticClass:"form-control",attrs:{"required":"","type":"text"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group contactForm col-md-6"},[_c('label',[_vm._v("Brief description of services/ prodcts provided")]),_c('textarea',{staticClass:"form-control",attrs:{"name":"","id":"","rows":"8"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"mt-4 text-center"},[_vm._v(" Already have an account? "),_c('a',{staticClass:"text-primary",attrs:{"href":"https://vendor.imperiumng.com/#/login","target":"_blank"}},[_vm._v("Login")])])
}]

export { render, staticRenderFns }